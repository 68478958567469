import React from 'react';
import { graphql } from 'gatsby';
import {
  ColorDataTableDesign,
  DontDo,
  DontDoItem,
  List,
  PageHero,
  PageNavigation,
  PageNavigationLink,
  PageWithSubNav,
  Paragraph,
  Section,
  SectionSubhead,
} from 'docComponents';
import pageHeroData from '../../../../data/pages/guidelines.yml';

const IndexPage = ({ data }) => {
  return (
    <PageWithSubNav
      pageType="design"
      title="Tagging"
      designCode
      subnav="guidelines">
      <PageHero heroData={pageHeroData} tierTwo="Colors" tierThree="Tagging" />

      <PageNavigation>
        <PageNavigationLink>Usage</PageNavigationLink>
        <PageNavigationLink>Values</PageNavigationLink>
      </PageNavigation>

      <Section>
        <Paragraph>
          These colors were chosen to effectively associate on-screen colors
          with team uniforms.
        </Paragraph>
      </Section>

      <Section title="Usage">
        <SectionSubhead>Text Color Contrast</SectionSubhead>

        <Paragraph>
          Each tagging color passes accessibility standards when used with the
          appropriate content color.
        </Paragraph>

        <List type="unordered">
          <li>
            Use <em>Content White</em> with most tagging colors: <em>Grey</em>,{' '}
            <em>Black</em>, <em>Red</em>, <em>Green</em>, <em>Blue</em> and{' '}
            <em>Purple</em>
          </li>
          <li>
            Use <em>Content Black</em> with lighter tagging colors:{' '}
            <em>White</em>, <em>Yellow</em> and <em>Orange</em>
          </li>
        </List>

        <DontDo gridLayout="2">
          <DontDoItem
            type="dont"
            text="use Content White on lighter tagging colors."
            img="colors-tagging-content-dont"
          />
          <DontDoItem
            type="do"
            text="use Content Black on White, Yellow and Orange."
            img="colors-tagging-content-do"
          />
        </DontDo>

        <SectionSubhead>Font Weight</SectionSubhead>

        <Paragraph>
          Even with the appropriate text color, some tagging base colors only
          pass accessibility at a lower threshold (3:1). Because of this, stick
          to bold text on base tagging colors.
        </Paragraph>
        <Paragraph>
          Shade tagging colors are suitable for all text weights and sizes.
        </Paragraph>
        <DontDo gridLayout="2">
          <DontDoItem
            type="dont"
            text="use regular weighted text on base colors."
            img="colors-tagging-bold-dont"
          />
          <DontDoItem
            type="do"
            text="use bold text on base colors and regular weighted text on shades."
            img="colors-tagging-bold-do"
          />
        </DontDo>
      </Section>

      <Section title="Values">
        <ColorDataTableDesign data={data} />
      </Section>
    </PageWithSubNav>
  );
};

export default IndexPage;

export const query = graphql`
  query taggingColorsDesign {
    allColorsYaml(filter: { type: { eq: "Tagging" } }) {
      edges {
        node {
          type
          colors {
            value
            common_name
          }
        }
      }
    }
  }
`;
